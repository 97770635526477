import { useMemo } from 'react'
import { useGetEmailCampaignActivityQuery } from '@sevenrooms/core/api'
import { EmailCampaignStatusEnum, MarketingCampaignTypeEnum, OngoingEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { format } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { DividerLine, HStack, Loader, VStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { useEmailCampaignPerformanceContext } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { addUpActivities, getPercentageOfTotal } from '@sevenrooms/mgr-marketing/utils'
import { marketingHomeMessages } from '../locales'

export function AutomatedEmailStatistics() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  const { currencySymbol } = venue
  const locale = venue.locale.replace('_', '-')

  // Fetch campaign activity data for all time
  const { campaigns, isCampaignQueryFetching, campaignActivityData, isActivityQueryFetching, isPOSActivated, averageSpendPerCover } =
    useEmailCampaignPerformanceContext()

  // Fetch campaign activity data just for today
  const today = new Date()
  const { data: todaysCampaignActivityData, isFetching: isTodaysCampaignActivityDataFetching } = useGetEmailCampaignActivityQuery({
    venueId: venue.id,
    startDate: format(today, 'yyyy-MM-dd'),
    endDate: format(today, 'yyyy-MM-dd'),
    campaignType: MarketingCampaignTypeEnum.ONGOING,
    campaignStatus: [EmailCampaignStatusEnum.ACTIVE, EmailCampaignStatusEnum.INACTIVE, EmailCampaignStatusEnum.DRAFT],
  })

  const isLoading = isCampaignQueryFetching || isActivityQueryFetching || isTodaysCampaignActivityDataFetching

  const numCampaigns = campaigns?.length ?? 0

  const stats = useMemo(() => {
    let totalRevenue = 0
    let emailsSentToday = 0
    let activeCampaigns = 0
    let totalRecipients = 0
    let openRate = 'NA'
    let highestPerformingCampaignLink = <>NA</>

    if (campaignActivityData) {
      const { campaignActivity: currentAct } = campaignActivityData
      const currentActTotal = addUpActivities(currentAct)

      totalRevenue = !isPOSActivated && averageSpendPerCover ? currentActTotal.covers * averageSpendPerCover : currentActTotal.totalRevenue
      totalRecipients = currentActTotal.recipients
      openRate = getPercentageOfTotal(currentActTotal.opens, currentActTotal.recipients)

      // Calculate a value for each campaign, filter out any with no value, sort them, and take the highest campaignId and build a link
      const campaignsSortedByValue = Object.entries(currentAct)
        .map(([campaignId, activity]) => {
          const campaignValue = !isPOSActivated && averageSpendPerCover ? activity.covers * averageSpendPerCover : activity.totalRevenue
          return { campaignId, campaignValue }
        })
        .filter(({ campaignValue }) => campaignValue > 0)
        .sort((a, b) => b.campaignValue - a.campaignValue)

      if (campaignsSortedByValue.length > 0 && campaignsSortedByValue[0]) {
        const highestPerformingCampaignId = campaignsSortedByValue[0].campaignId

        const highestPerformingCampaign = campaigns?.find(campaign => campaign.id === highestPerformingCampaignId)
        let highestPerformingCampaignEditUrl
        if (highestPerformingCampaign?.parentTemplateId) {
          highestPerformingCampaignEditUrl = nav.href(routes.manager2.marketing.emailCenter.emails.editEmail, {
            params: { venueKey: venue.urlKey, campaignId: highestPerformingCampaignId },
          })
        } else {
          highestPerformingCampaignEditUrl = nav.href(routes.manager2.marketing.emailCenter.emails.editEmailFromScratch, {
            params: { venueKey: venue.urlKey, campaignId: highestPerformingCampaignId },
          })
        }
        highestPerformingCampaignLink = <a href={highestPerformingCampaignEditUrl}>{highestPerformingCampaign?.campaignName}</a>
      }
    }

    activeCampaigns = campaigns?.filter(campaign => campaign.status === OngoingEmailCampaignStatusEnum.ACTIVE).length ?? 0

    if (todaysCampaignActivityData) {
      const { campaignActivity: currentAct } = todaysCampaignActivityData
      const currentActTotal = addUpActivities(currentAct)

      emailsSentToday = currentActTotal.recipients
    }

    return [
      {
        name: formatMessage(marketingHomeMessages.automatedEmailStatsRevenue),
        value: `${currencySymbol}${Math.round(totalRevenue).toLocaleString(locale)}`,
      },
      {
        name: formatMessage(marketingHomeMessages.automatedEmailStatsEmailsToday),
        value: `${emailsSentToday}`,
      },
      {
        name: formatMessage(marketingHomeMessages.automatedEmailStatsActiveCampaigns),
        value: `${activeCampaigns}`,
      },
      {
        name: formatMessage(marketingHomeMessages.automatedEmailStatsRecipients),
        value: `${totalRecipients.toLocaleString(locale)}`,
      },
      {
        name: formatMessage(marketingHomeMessages.automatedEmailStatsOpenRate),
        value: `${openRate}`,
      },
      {
        name: formatMessage(marketingHomeMessages.automatedEmailStatsHighestPerformingCampaign),
        value: highestPerformingCampaignLink,
      },
    ]
  }, [
    campaigns,
    campaignActivityData,
    isPOSActivated,
    averageSpendPerCover,
    todaysCampaignActivityData,
    venue,
    currencySymbol,
    locale,
    formatMessage,
    nav,
  ])

  return (
    <Box width="486px" p="m" borderColor="dividerLines" borderRadius="s" borderWidth="s" backgroundColor="primaryBackground">
      <VStack justifyContent="space-between" spacing="m" pt="s">
        {/* Title and action button */}
        <VStack spacing="sm">
          <HStack justifyContent="space-between">
            <Header type="h2" lineHeight="l">
              {formatMessage(marketingHomeMessages.automatedEmailsTitle)}
            </Header>
            <Button
              variant="tertiary"
              noPadding
              type="button"
              href={nav.href(routes.manager2.marketing.emailCenter.emails, { params: { venueKey: venue.urlKey } })}
              data-test="setup-automated-emails-button"
            >
              {formatMessage(marketingHomeMessages.automatedEmailsAction)}
            </Button>
          </HStack>
          {!(isCampaignQueryFetching || isActivityQueryFetching) && numCampaigns > 0 && (
            <Text type="p">{formatMessage(marketingHomeMessages.automatedEmailsStatsDescription)}</Text>
          )}
        </VStack>

        {/* Loader */}
        {isLoading && <Loader />}

        {/* Setup button */}
        {!isLoading && numCampaigns === 0 && (
          <VStack
            spacing="sm"
            backgroundColor="insetBackground"
            textAlign="center"
            justifyContent="center"
            height="198px"
            pt="s"
            pb="s"
            pl="xxs"
            pr="xxs"
            borderRadius="s"
          >
            <Header type="h2">{formatMessage(marketingHomeMessages.automatedEmailsNoCampaigns)}</Header>
            <Box textAlign="center">
              <Button
                variant="primary"
                size="s"
                type="button"
                href={nav.href(routes.manager2.marketing.emailCenter.emails, { params: { venueKey: venue.urlKey } })}
                data-test="setup-automated-emails-button"
              >
                {formatMessage(marketingHomeMessages.automatedEmailsSetup)}
              </Button>
            </Box>
          </VStack>
        )}

        {/* Stats list */}
        {!isLoading && numCampaigns > 0 && (
          <VStack spacing="sm" pt="s">
            {stats.map(({ name, value }, idx, arr) => (
              <VStack key={name} spacing="xs">
                <HStack justifyContent="space-between">
                  <Box>
                    <Text type="span" color="primaryIcons">
                      {name}
                    </Text>
                  </Box>
                  <Box>
                    <Text type="span" fontWeight="bold" color="primaryIcons">
                      {value}
                    </Text>
                  </Box>
                </HStack>
                {idx !== arr.length - 1 && <DividerLine margin="none" />}
              </VStack>
            ))}
          </VStack>
        )}
      </VStack>
    </Box>
  )
}
