import { useMemo } from 'react'
import { v4 } from 'uuid'
import { useWatch, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Label } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, Pair, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { checkTimeBeforeWarning, generateTimeSlots, type TimeBeforeForm, useAccessRuleContext } from '../../shared'
import { CutoffTimeWarningTooltip } from '../../shared/CutoffTimeWarningTooltip'
import { BookingChannelsLocales } from '../BookingChannels.locales'
import { type BookingChannelsForm, flattenAudience } from '../BookingChannels.zod'
import { BookingChannelAudience } from './BookingChannelAudience'
import type { BookingWindowForm } from '../../BookingWindow/BookingWindow.zod'

export interface CollapsedBookingChannelsProps {
  field: Field<BookingChannelsForm>
  bookingWindowField: Field<BookingWindowForm>
}

export function CollapsedBookingChannels({ field, bookingWindowField }: CollapsedBookingChannelsProps) {
  const { formatMessage } = useLocales()
  const { audienceHierarchy } = useAccessRuleContext()
  const { startOfDayTime } = useAppContext().venueSettings

  const bookingChannels = useWatch(field)
  const cutoffTime = useWatch(bookingWindowField.prop('cutoffTime'))
  const timeSlots = useMemo(() => generateTimeSlots(startOfDayTime), [startOfDayTime])

  const selectedAudiences = useMemo(() => {
    const flattenedAudience = flattenAudience(audienceHierarchy)
    return bookingChannels
      ? bookingChannels.map(tier => ({
          ...tier,
          selected: tier.selected.map(selected => {
            const option = flattenedAudience.find(option => option.value === selected.id)?.children
            return {
              ...selected,
              childCount: option ? option.length : 0,
            }
          }),
        }))
      : null
  }, [audienceHierarchy, bookingChannels])

  const getSelectedAudienceLabel = (selectedOptions: { id: string; label: string; childCount: number }[]) =>
    selectedOptions.map(selected => (selected.childCount > 0 ? `${selected.label} (${selected.childCount})` : selected.label)).join(', ')

  return (
    selectedAudiences && (
      <Text fontSize="m">
        <VStack spacing="s" data-test="booking-channels-collapsed">
          {selectedAudiences.map((tier, index) => (
            <div key={tier.audienceTierId ?? v4()} data-test={`booking-channels-collapsed-${index}`}>
              <Pair left={formatMessage(BookingChannelsLocales.bookingChannelsLabel)} right={getSelectedAudienceLabel(tier.selected)} />
              <Box mt="sm">
                <Pair
                  left={formatMessage(BookingChannelsLocales.audienceLabel)}
                  right={
                    <Label
                      primary={<BookingChannelAudience tier={tier} />}
                      secondary={
                        bookingChannels && bookingChannels?.length > 1 && tier.startTime.count ? (
                          <BookingAheadLabel startTime={tier.startTime} cutoffTime={cutoffTime} timeSlots={timeSlots} />
                        ) : (
                          ''
                        )
                      }
                    />
                  }
                />
              </Box>
            </div>
          ))}
        </VStack>
      </Text>
    )
  )
}

interface BookingAheadLabelProps {
  startTime: TimeBeforeForm
  cutoffTime: TimeBeforeForm
  timeSlots: SelectOption[]
}

function BookingAheadLabel({ startTime, cutoffTime, timeSlots }: BookingAheadLabelProps) {
  const { formatMessage } = useLocales()

  const before = startTime.beforeTime === '0' ? startTime.beforeTime : timeSlots.find(item => item.id === startTime.beforeTime)?.label
  const hasBookingChannelsWarning = checkTimeBeforeWarning(startTime, cutoffTime)

  return (
    <HStack spacing="xs" mt="s">
      <Text color="secondaryFont">{formatMessage(BookingChannelsLocales.canBook, { ...startTime, before })}</Text>
      {hasBookingChannelsWarning && <CutoffTimeWarningTooltip hasBookingChannelsWarning />}
    </HStack>
  )
}
